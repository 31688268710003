
function toggleTableAnnexe($this) {
    var idFormation = $this.attr('id');
    var idTable = "table-"+idFormation;
    $this.find("button .fa").toggleClass("fa-angle-right").toggleClass("fa-angle-down");

    if($("#table-"+idFormation+"_wrapper").length > 0) {
        // Si la datable est deja créée pour ce tableau : on le rend visible directement
        $("#table-"+idFormation+"_wrapper, #table-"+idFormation+"_wrapper .table-annexes").toggle();
    }
    else {
        // Sinon on créé la datable, puis on rend visible ce tableau
        $('#'+idTable).toggle();
        initTableAnnexe(idTable);
    }

}

function initTableAnnexe(idTable, columnForOrdering = 1) {
    // Si le tableau annexe existe
  if($('#'+idTable).length > 0){
        $.fn.dataTable.moment( 'DD/MM/YYYY');
        var table = $('#'+idTable).DataTable({
            "language": {
                url: 'https://cdn.datatables.net/plug-ins/1.10.15/i18n/French.json'
            },
            "columnDefs": [
                { "orderable": false, "targets": 2 },
            ],
            "processing": true,
            "info": false,
            "order": [[ columnForOrdering, "desc" ]]
        });
        // Mise a jour des fichiers cachées à chaque rafraichissement
        var btnMontrerElement  = $('.btn-montrer-element');
        var downloaded = $('.downloaded');
        $('.table-annexes').on( 'draw.dt', function () {
            downloaded.each(function(index){
                if ( btnMontrerElement.hasClass('hidden') ){
                    $(this).removeClass('hidden');
                }
                else {
                    $(this).addClass('hidden');
                }
            });
        });
    }
}

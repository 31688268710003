var initPopin = false;

function scrollCartouche() {
    var scrollToLastMessage = $('.modal .message').last().offset().top - 110;
    /*Déplacement du cartouche*/
    $('.informations').css('transform', 'translateY(' + scrollToLastMessage + 'px)');
    /*Animation à l'ouverture de la popin pour aller au dernier message*/
    $('.popin-messagerie').animate({
        scrollTop: scrollToLastMessage
    }, function() {
       initPopin = true;
   });
}
/*lorsque l'on scroll pour que le cartouche reste fix*/
$('.popin-messagerie').scroll(function() {
    if (initPopin) {
        if($(window).width() > 1200 ) {
            var yDiff = $(this).scrollTop();
            if (yDiff < 0) {
                yDiff = 0;
            }
            $('.informations').css('transform', 'translateY(' + yDiff + 'px)');
        } else {
            $('.informations').css('transform', 'translateY(0px)');
        }
    }
});

var MsgModoTest = {
    time: 6000,
    table: undefined,
    urlAjax : undefined,
    init: function(t) {
        this.time = t;
        var that = this;
        setInterval(function() {
            that.call();
            that.nbMessages();
        }, this.time);
        this.listener();
        this.gestionMultiModal();
    },
    call: function() {
        var that = this;

        $.ajax({
            url: that.urlAjax,
            cache: false
        }).done(function(data) {
            html = $(data.tickets.trim().replace(/\s+/g, " ").replace(/\<\/tr\> \<tr/g, '</tr><tr'));
            that.table.clear();
            that.table.rows.add(html).draw(false);
        });

    },
    nbMessages: function() {
        var that = this;
        $.ajax({
            url: that.urlAjax +'/getNbNewMessages',
            type: 'GET',
            dataType: 'html',
            success: function(nbMessages,statut) {
                if(nbMessages != 0) {
                    $('#nbNewMsg').html('('+nbMessages+')');
                } else {
                    $('#nbNewMsg').html('');
                }
            }
        });
    },
    listener: function() {
        $('body').on('click', '.messagerie .ticket a:not(.archive-toogle)', function(e) {
            e.preventDefault();
            var pop = $('.popin-messagerie');
            pop.find('.modal-body').html('');
            pop.find('.title').html('<h2>Conversation</h2>');
            $.ajax({
                url: $(this).attr('href'),
                cache: false
            }).done(function(data) {
                pop.find('.title').data('current-ticket', data.ticketId);
                pop.find('.modal-body').html(data.messageView);
                initWysiwyg();
                scrollCartouche();
            });
            pop.modal({});
            pop.on('hidden.bs.modal', function() {
                pop.find('.modal-body').find("*").off();
                $(document).off('click', '.modal.popin .transfertServiceDestinataire');
            });
        });
    },
    gestionMultiModal: function() {
        $(document).on('show.bs.modal', '.modal', function(event) {
            var zIndex = 1040 + (10 * $('.modal:visible').length);
            $(this).css('z-index', zIndex);
            setTimeout(function() {
                $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
            }, 0);
        });
    }
}

var isInitAjaxFileDownload = false;

function initAjaxFileDownload() {

    if (!isInitAjaxFileDownload) {
        $('#form-download-export').submit(function() {
            $('.alert-danger').hide().empty();
            lockUIForDownload();
        });

        function lockUI() {
            $('.btn-start-download').button('loading');
        }

        function unLockUI() {
            $('.btn-start-download').button('reset');
        }

        function downloadStarted() {
            window.clearInterval(fileDownloadCheckTimer);
            Cookies.remove('fileDownloadToken'); //clears this cookie value
            unLockUI();
        }

        var fileDownloadCheckTimer;

        function lockUIForDownload() {
            var token = new Date().getTime().toString(); //use the current timestamp as the token value
            $('#download-token').val(token);

            lockUI();

            fileDownloadCheckTimer = window.setInterval(function() {
                var cookieValue = Cookies.get('fileDownloadToken');
                if (cookieValue === token) {
                    downloadStarted();
                }
            }, 500);
        }
    }
    isInitAjaxFileDownload = true;
}

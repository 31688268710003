titrePlateformeDevoirs = $('.tutosPlateformeDevoirs .titre');
liensVideosPlateformeDevoirs = $('.tutosPlateformeDevoirs .liensVideos');

actualitesWrapper = $('.actualites-raccourcis .actualites-wrapper');
actualitesSimples = $('.actualites-raccourcis .actualites-simples');
actualitesImportantes = $('.actualites-raccourcis .actualites-importantes');
boutonsRaccourcisWrapper = $('.actualites-raccourcis .boutons-raccourcis-wrapper');
boutonsRaccourcis = $('.actualites-raccourcis .boutons-raccourcis');
boutonRaccourcis = $('.actualites-raccourcis .bouton-raccourcis');

/*
* Met le carousel bootstrap en pause lors du clic sur une des flèches,
* sur un point ou sur le bouton "lire la suite"
*/
$('.carousel').on('click', '.carousel-control, .carousel-dot-control', function() {
	pauseCarousel($(this));
});

//Ne marche pas (???)
$('.carousel').on('click', '.lire-plus', function() {
	pauseCarousel($(this));
});

var pauseCarousel = function(current)
{
	slider = current.parents('.carousel');
	slider.carousel('pause');
}

//JS desktop
if($(window).width() > 992)
{
		boutonsRaccourcisWrapper.css('padding', '30px 120px');
	//Initialisation des popup d'aide sur les boutons
	$('[data-toggle="tooltip"]').tooltip({container: 'body'});
}


//JS mobile
else
{
	limiteCaracteres = 130;

	$('.actualites .item .actu-contenu').each(function()
	{

		var allContent = $(this).find('p, h1, h2, h3, h4, h5, h6, blockquote').text() + $(this).text();

		if(allContent.length > limiteCaracteres)
			{
				// Ajout du bouton lirePlus
				$(this).parent('.item').append(
					'<div class="lirePlus">' +
						'<i class="fa fa-chevron-down yellow"></i>' +
					'</div>');

				//scroll sur click de lirePlus
				$(this).closest('.item').find('.lirePlus').click(function(){
					$(this).css('display', 'none');
					var contentItem = $(this).parent('.item');
					var y = contentItem.scrollTop();
					contentItem.scrollTop(y+150, 150);

				});

				$(this).closest('.item').scroll(function(){
					$(this).find('.lirePlus').css('display', 'none');
				});

			}

	});


	titrePlateformeDevoirs.attr('data-toggle', 'collapse');
	titrePlateformeDevoirs.attr('data-target', '#liensVideosPlateformeDevoirs');

	liensVideosPlateformeDevoirs.addClass('collapse');
	liensVideosPlateformeDevoirs.attr('id', 'liensVideosPlateformeDevoirs');
	liensVideosPlateformeDevoirs.attr('aria-expanded', 'true');
	liensVideosPlateformeDevoirs.collapse('hide');
}


$('.modal').on('hidden.bs.modal', function(){
	$('.modal-body iframe.video').attr('src', '');
});


//Gestion de l'accordéon mobile
$('#mainMenuPhone').on('show.bs.collapse', function () {
    $('#mainMenuPhone .in').collapse('hide');
});

$('#mainMenuPhone .mainItem').on('click', function(){
	$('.mainItem').each(function(){
		$(this).removeClass('more');
	});


	$('.header-sujet-devoir').removeClass('more');
	if($(this).attr('aria-expanded') == 'true') {
		$(this).removeClass('more');
	} else {
		$(this).addClass('more');
	}

});